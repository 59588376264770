<!-- Loading bar -->
<div class="w-screen h-screen flex">
    <fuse-loading-bar></fuse-loading-bar>

    <div
        class="flex flex-col items-center justify-between gap-4 bg-slate-800 fixed h-full z-99 p-2"
    >
        <div>
            <div class="flex items-center justify-center mt-2">
                <img
                    class="w-12"
                    src="/assets/images/logo/mindbowser-logo-white.svg"
                />
            </div>
            <div class="flex flex-col justify-center items-center gap-4 mt-4">
                <ng-container *ngFor="let nav of navigations">
                    <ng-container
                        *ngIf="nav?.allowedRoles?.includes(userState?.role)"
                    >
                        <mat-icon
                            [svgIcon]="nav?.icon"
                            (click)="navigate(nav?.link)"
                            class="cursor-pointer hover:text-white"
                            [matTooltip]="nav?.title"
                            [ngClass]="{
                                'text-white':
                                    currentRoute.indexOf(nav?.link) !== -1
                            }"
                        ></mat-icon>
                    </ng-container>
                </ng-container>
            </div>
        </div>
        <div>
            <user [showAvatar]="false"></user>
            <div class="text-gray-600 mt-2">{{ appVersion }}</div>
        </div>
    </div>

    <div class="flex flex-col w-full min-w-0 h-full pl-12">
        <div
            class="relative flex flex-0 items-center w-full py-4 px-8 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
        >
            <h1 class="font-semibold tracking-tight leading-none m-0">
                {{ pageTitle }}
            </h1>
        </div>
        <div class="flex flex-col h-full">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
