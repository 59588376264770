import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrong-url',
  templateUrl: './wrong-url.component.html',
  styleUrls: ['./wrong-url.component.scss']
})
export class WrongUrlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
