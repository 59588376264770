import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from './core/shared.module';
import { AuthGuard } from '@services/auth/guards/auth.guard';
import { NoAuthGuard } from '@services/auth/guards/noAuth.guard';
import { LayoutComponent } from './layout/layout.component';
import { WorkLogsComponent } from '@modules/public/shared/work-logs/work-logs.component';
import { WrongUrlComponent } from '@modules/public/shared/wrong-url/wrong-url.component';
// import { FullIssueDetailComponent } from './modules/admin/kanban/project/pages/full-issue-detail/full-issue-detail.component';
export const appRoutes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "sign-in" },

  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "sign-in" },
  // Auth routes for guests
  {
    path: "",
    canActivate: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-in",
        loadChildren: () =>
          import("./modules/auth/sign-in/sign-in.module").then(
            (m) => m.AuthSignInModule
          ),
      },
    ],
  },
  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      pageTitle: "dashboard",
    },

    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/admin/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "projects",
        loadChildren: () =>
          import("./modules/admin/project/project.module").then(
            (m) => m.ProjectModule
          ),
      },
      // {
      //     path: 'kanban-board',
      //     loadChildren: () =>
      //         import('./modules/admin/kanban/kanban.module').then(
      //             (m) => m.KanbanBoardModule
      //         ),
      // },
      {
        path: "resources",
        loadChildren: () =>
          import("./modules/admin/resources/resources.module").then(
            (m) => m.ResourcesModule
          ),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./modules/admin/create-form/create-form.module").then(
            (m) => m.CreateFormModule
          ),
      },
      {
        path: "platform-users",
        loadChildren: () =>
          import("./modules/admin/platform-users/platform-users.module").then(
            (m) => m.PlatformUsersModule
          ),
      },
      {
        path: "external-projects",
        loadChildren: () =>
          import(
            "./modules/admin/external-projects/external-projects.module"
          ).then((m) => m.ExternalProjectsModule),
      },
      {
        path: "profit-loss",
        loadChildren: () =>
          import("./modules/admin/profit-loss/profit-loss.module").then(
            (m) => m.ProfitLossModule
          ),
      },
      {
        path: "mentee",
        loadChildren: () =>
          import("./modules/admin/mentee/mentee.module").then(
            (m) => m.MenteeModule
          ),
      },
      {
        path: "inbox",
        data: {
          pageTitle: "Inbox",
        },
        loadChildren: () =>
          import(
            "./modules/react/modules/angular-to-react/angular-to-react.module"
          ).then((m) => m.AngularToReactModule),
      },
      {
        path: "feedback",
        data: {
          pageTitle: "Feedback",
        },
        loadChildren: () =>
          import(
            "./modules/react/modules/angular-to-react/angular-to-react.module"
          ).then((m) => m.AngularToReactModule),
      },
      {
        path: "goals",
        data: {
          pageTitle: "Goals",
        },
        loadChildren: () =>
          import(
            "./modules/react/modules/angular-to-react/angular-to-react.module"
          ).then((m) => m.AngularToReactModule),
      },
      {
        path: "documents-repo",
        data: {
          pageTitle: "Document Repository",
        },
        loadChildren: () =>
          import(
            "./modules/react/modules/angular-to-react/angular-to-react.module"
          ).then((m) => m.AngularToReactModule),
      },
      {
        path: "profile",
        data: {
          pageTitle: "Profile",
        },
        loadChildren: () =>
          import("./modules/admin/profile/profile.module").then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: "wfh",
        data: {
          pageTitle: "WFH request",
        },
        loadChildren: () =>
          import(
            "./modules/react/modules/angular-to-react/angular-to-react.module"
          ).then((m) => m.AngularToReactModule),
      },
    ],
  },
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "client-portal",
        loadChildren: () =>
          import("./modules/public/shared/shared.module").then(
            (m) => m.SharedModule
          ),
      },
    ],
  },
  {
    path: "work-logs/:id",
    component: WorkLogsComponent,
  },
  {
    path: "wrong-url",
    component: WrongUrlComponent,
  },
  {
    path: "resource",
    loadChildren: () =>
      import("./modules/public/resource/resource.module").then(
        (m) => m.ResourceModule
      ),
  },
  {
    path: "worklog",
    loadChildren: () =>
      import("./modules/public/worklog/worklog.module").then(
        (m) => m.WorklogModule
      ),
  },
  {
    path: "documents",
    loadChildren: () =>
      import(
        "./modules/react/public/documents/documents/documents.module"
      ).then((m) => m.DocumentsModule),
  },
  {
    path: "**",
    redirectTo: "sign-in",
    pathMatch: "full",
  },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            // initialNavigation: 'enabled',
            scrollPositionRestoration: 'enabled',
        }),
        SharedModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
