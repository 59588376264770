<ng-particles
    [id]="id"
    [options]="particlesOptions"
    [particlesInit]="particlesInit"
    (particlesLoaded)="particlesLoaded($event)"
></ng-particles>
<div class="w-screen h-screen flex flex-col justify-center items-center">
    <span class="text-2xl text-center sm:text-4xl text-green-500 font-bold"
        >Successfully logged work !</span
    >
    <span class="mt-2">Sit back and enjoy explosions</span>
</div>
