<div
    *ngIf="!responseSubmitted && !initialLoading"
    class="min-h-screen flex justify-center items-center bg-gray-50 transition-all"
>
    <div class="w-full max-w-screen-md p-2 sm:p-6">
        <div class="w-full flex justify-center mb-2">
            <div>
                <img class="w-36" src="assets/images/logo/logo.svg" alt="" />
            </div>
        </div>

        <div
            class="w-full max-w-[700px] m-auto mt-8 shadow-lg p-2 py-4 sm:p-8 rounded-lg bg-white"
        >
            <div class="mb-2">
                <span class="text-xl"
                    >Hi, {{ resourceData?.resourceName | titlecase }}</span
                >
            </div>
            <span class="text-gray-500 text-2xl"
                >{{ resourceData?.projectName }} : Work log for
                {{ resourceData?.workLogDate | date }}</span
            >
            <form class="mt-8" [formGroup]="workLogForm">
                <div class="flex flex-col">
                    <div class="flex-1">
                        <mat-checkbox 
                                [checked]="onLeave"
                                (change)="checkBox($event?.checked)"
                            ><span>I was on Leave</span></mat-checkbox
                        >

                        <mat-checkbox 
                        class="ml-6"
                                [checked]="onHoliday"
                                (change)="checkBoxHoliday($event?.checked)"
                        ><span>Holiday</span></mat-checkbox
                        >
                    </div>

                    <div class="w-full">
                        <div class="flex items-center mt-6">
                            <mat-form-field
                                class="w-full"
                                [floatLabel]="'always'"
                            >
                                <mat-label>Hours</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    placeholder="Hours"
                                    formControlName="totalHours"
                                    [ngClass]="{
                                        'cursor-not-allowed': onLeave || onHoliday
                                    }"
                                />
                                <mat-error
                                    *ngIf="
                                        workLogForm
                                            ?.get('totalHours')
                                            ?.hasError('required')
                                    "
                                    class="text-red-500"
                                >
                                    <div>Hours is mandatory</div>
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        workLogForm
                                            ?.get('totalHours')
                                            ?.hasError('pattern')
                                    "
                                    class="text-red-500"
                                >
                                    <div>Please enter valid hours</div>
                                </mat-error>
                                <mat-error
                                    *ngIf="
                                        workLogForm
                                            ?.get('totalHours')
                                            ?.hasError('max')
                                    "
                                    class="text-red-500"
                                >
                                    <div>
                                        Hours should not be greater than 24
                                    </div>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="flex-1 mt-4">
                            <span class="font-semibold mb-4"
                                >Description *</span
                            >
                            <div class="mt-2">
                                <quill-editor
                                    [ngClass]="{
                                        'cursor-not-allowed': onLeave || onHoliday
                                    }"
                                    #editor
                                    [modules]="modules"
                                    (onContentChanged)="getDescription($event)"
                                    [(ngModel)]="quillValue"
                                    [readOnly]="onLeave"
                                    [ngModelOptions]="{ standalone: true }"
                                ></quill-editor>
                            </div>
                        </div>

                        <div class="flex justify-end mt-4">
                            <button
                                [disabled]="onLeave || onHoliday"
                                class="bg-primary-500 text-white font-bold ml-2"
                                mat-flat-button
                                type="submit"
                                [ngClass]="{
                                    'cursor-not-allowed': onLeave || onHoliday
                                }"
                                (click)="workLogForm?.valid ? addTask() : null"
                            >
                                <mat-icon
                                    svgIcon="heroicons_outline:plus"
                                ></mat-icon>
                                {{ editMode ? "SAVE" : "ADD" }}
                            </button>
                        </div>
                    </div>

                    <div class="flex flex-auto overflow-hidden my-8">
                        <div
                            class="flex flex-col flex-auto overflow-hidden sm:overflow-y-auto"
                        >
                            <div
                                *ngIf="tasks?.length > 0"
                                class="inventory-grid z-10 sticky top-0 grid gap-4 py-4 px-6 md:px-8 shadow text-md font-semibold text-secondary bg-gray-50 dark:bg-black dark:bg-opacity-5"
                                matSortDisableClear
                            >
                                <div class="primary-900">Description</div>
                                <div class="primary-900">Hours</div>
                                <div class="">Actions</div>
                            </div>
                            <ng-container>
                                <div
                                    *ngIf="tasks?.length > 0"
                                    class="grid max-h-[500px]"
                                >
                                    <ng-container
                                        *ngFor="let task of tasks; index as i"
                                    >
                                        <div
                                            class="inventory-grid grid items-center gap-4 py-4 px-6 md:px-8 border-b h-67"
                                        >
                                            <div
                                                class="truncate font-semibold text-black"
                                            >
                                                <div class="truncate">
                                                    {{ task?.description }}
                                                </div>
                                            </div>

                                            <div
                                                class="truncate font-semibold text-black"
                                            >
                                                <div class="truncate">
                                                    {{ task?.timeSpent }}
                                                </div>
                                            </div>

                                            <div class="md:block">
                                                <mat-icon
                                                    (click)="editTask(task, i)"
                                                    [matTooltip]="
                                                        'Download resume'
                                                    "
                                                    class="text-gray-400 icon-size-5 cursor-pointer"
                                                    svgIcon="heroicons_outline:pencil"
                                                ></mat-icon>
                                                <mat-icon
                                                    (click)="removeTask(i)"
                                                    [matTooltip]="
                                                        'Delete resume'
                                                    "
                                                    class="ml-4 text-gray-400 icon-size-5 cursor-pointer"
                                                    svgIcon="heroicons_outline:trash"
                                                ></mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div
                                class="inventory-grid grid my-4"
                                *ngIf="tasks?.length > 0"
                            >
                                <div
                                    class="flex justify-end mr-6 text-xl font-semibold text-black"
                                >
                                    Total Hours
                                </div>
                                <div
                                    class="font-semibold -ml-1 text-xl text-black"
                                >
                                    {{ totalHours }}
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-end mt-4">
                        <button
                            class="bg-primary-500 text-white font-bold"
                            mat-flat-button
                            type="submit"
                            (click)="submit()"
                            [disabled]="submitInProgress"
                        >
                            <mat-progress-spinner
                                *ngIf="submitInProgress"
                                class="mr-2"
                                [diameter]="24"
                                [mode]="'indeterminate'"
                            ></mat-progress-spinner>
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div
    *ngIf="initialLoading"
    class="w-screen h-screen flex justify-center items-center bg-gray-50 overflow-x-hidden"
>
    <div class="w-full max-w-screen-md p-2 sm:p-6">
        <div class="w-full flex justify-center mb-2">
            <div>
                <img class="w-36" src="assets/images/logo/logo.png" alt="" />
            </div>
        </div>

        <div
            class="w-full max-w-[700px] m-auto mt-8 shadow-sm p-2 py-4 sm:p-8 rounded-lg bg-white"
        >
            <div class="skeleton-loader w-1/2">
                <div class="table_row rounded-2xl"></div>
            </div>
            <form class="mt-8" [formGroup]="workLogForm">
                <div class="flex flex-col">
                    <div class="skeleton-loader w-24">
                        <div class="table_row rounded-2xl"></div>
                    </div>
                    <div class="flex-1 mt-6">
                        <div class="skeleton-loader w-full">
                            <div class="table_row rounded-2xl"></div>
                        </div>
                    </div>

                    <div class="mt-4">
                        <div class="skeleton-loader_description">
                            <div class="table_row rounded-2xl"></div>
                        </div>
                    </div>

                    <div class="flex justify-end mt-4">
                        <div class="skeleton-loader w-18">
                            <div class="table_row rounded-2xl"></div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="responseSubmitted && !initialLoading">
    <app-work-logs-success></app-work-logs-success>
</div>
