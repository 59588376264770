import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  constructor() { }

  pageTitle = new BehaviorSubject(null);

  setPageTitle(title){
      this.pageTitle.next(title);
  }
}
